<template>
  <div id="app">
    <router-view />

    <!-- <div class="state" @click="lookState">版权所有 © 2022 亿链纵横 使用前请知晓《免责申明》</div> -->
    <Tabbar v-if="isMobile" />
  </div>
</template>

<script>
import Vue from "vue";
import Tabbar from "./components/content/Tabbar.vue";

Vue.directive("cursor", {
  bind: function (el, binding, vnode) {
    let show = true;
    const blink = () => {
      el.style.visibility = show ? "visible" : "hidden";
      show = !show;
    };
    const timer = setInterval(blink, 500);

    el._timer = timer;
  },
  unbind: function (el, binding, vnode) {
    clearInterval(el._timer);
  },
});
export default {
  name: "App",
  methods: {
    lookState() {
      this.$router.push({ path: "/statement" });
    },
  },
  components: { Tabbar },
  computed: {
    // 判断移动端
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
p {
  margin: 0;
}
div {
  box-sizing: border-box;
}
body {
  margin: 0;
  position: relative;
}
::v-deep .el-button:focus,
.el-button:hover {
  color: #22938c;
}
.btn {
  display: inline-block;
  width: 110px;
  height: 34px;
  padding: 0 0px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 17px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
  &:hover {
    background: #12a4a5;
  }
}
.move-btn {
  width: 34px;
  height: 34px;
  background: #ffb801;
  border-radius: 17px;
  transition: all 0.3s ease-in-out;
  margin-right: 8px !important;
  span {
    display: none;
    height: 18px;
    overflow: hidden;
  }
  &:hover {
    width: 110px;
    height: 34px;
    transition: all 0.3s ease-in-out;
    span {
      display: inline-block;
    }
  }
}

.input-box {
  margin: 0 auto;
  width: 720px;
  border-radius: 30px;
  border: 1px solid #e5e5e5;
  margin-top: 18px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 24px;
  box-sizing: border-box;
  input,
  textarea {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    color: #fff;
    font-size: 14px;
    margin-right: 10px;
    vertical-align: middle;
    font-family: PingFangSC-Regular, PingFang SC;
    resize: none; /* 禁止拖动改变大小 */
    line-height: 20px;
    text-align: justify;
    &::placeholder {
      font-size: 14px;
      color: #ccc;
      line-height: 20px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  img {
    width: 30px;
    height: 30px;
    cursor: pointer;
    vertical-align: bottom;
    align-self: flex-end;
  }
}
.home-input {
  height: 60px;
  background: rgba(255, 255, 255, 0.3);
  input {
    font-size: 18px;
    color: #fff;
    &::placeholder {
      font-size: 18px;
      color: #e5e5e5;
    }
  }
  img {
    align-self: auto;
  }
}
.blue-btn {
  background: linear-gradient(180deg, #29d1d1 0%, #12a4a5 100%);
  &:hover {
    background: linear-gradient(180deg, #2ee8e8 0%, #16bcbd 100%);
  }
}

.document-analysis {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  width: 100%;
  background: url("./assets/images/document-analysis.png");
  background-size: cover;
  margin-top: 24px;
  cursor: pointer;
  img {
    width: 18px;
    height: 20px;
    margin-right: 8px;
    vertical-align: middle;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 18px;
    height: 18px;
    color: #ffffff;
  }
}
.flex {
  display: flex;
}
.mr-3 {
  margin-right: 3px;
}
.disabled {
  cursor: no-drop;
}
.state {
  position: absolute;
  bottom: 2vh;
  width: 100%;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  color: #fff;
  text-decoration: underline;
}

// 旋转
.rotate {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cursor {
  display: inline-block;
  width: 1px;
  height: 20px;
  background: #ffffff;
  vertical-align: middle;
  margin: 0 6px;
}
</style>
